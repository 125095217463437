if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", initSiteHeaderScripts)
} else {
    initSiteHeaderScripts()
}

function initSiteHeaderScripts() {
    setTimeout(async () => {
        // Use Promise.all to dynamically import all the necessary modules
        const [
            {loadTranslations},
            {createPinia},
            {elements},
            {initMenu},
            {bindUserMenuBtn},
            {initPopupBladeClose},
            {initShowHidePassword, initPasswordStrengthChecker},
            {initLogoutButtons},
            {initDOMContentLoaded},
            {setInputTokens},
            {initVerifyResend},
            {handlePackageReload},
            {bindHistoryGoBack},
            {bindCertificateDownload},
            {bindUseContentButtons},
            {preventDefaultDownloadButtons},
            {initSeoVideo},
            {modifyRelForExternalLinks},
            {hideFooterForLoggedInUsers},
            {initDeferredVueApps},
            {initHelpModule},
            {handleHashScroll, handleScrollEvents},
            {googleAdsInit},
            {lanugageNotificationInit}
        ] = await Promise.all([
            import("@/libs/helpers.ts"), // loadTranslations
            import("pinia"), // createPinia
            import("@/site-header/elements.ts"), // elements
            import("@/site-header/menu.ts"), // initMenu
            import("@/site-header/userMenu.ts"), // bindUserMenuBtn
            import("@/site-header/popup.ts"), // initPopupBladeClose
            import("@/site-header/password.ts"), // initShowHidePassword, initPasswordStrengthChecker
            import("@/site-header/logout.ts"), // initLogoutButtons
            import("@/site-header/domContentLoaded.ts"), // initDOMContentLoaded
            import("@/site-header/utils.ts"), // setInputTokens
            import("@/site-header/verifyResend.ts"), // initVerifyResend
            import("@/site-header/packageReload.ts"), // handlePackageReload
            import("@/site-header/historyGoBack.ts"), // bindHistoryGoBack
            import("@/site-header/certificateDownload.ts"), // bindCertificateDownload
            import("@/site-header/useContentButtons.ts"), // bindUseContentButtons
            import("@/site-header/preventDefaultDownloadButtons.ts"), // preventDefaultDownloadButtons
            import("@/site-header/seoVideo.ts"), // initSeoVideo
            import("@/site-header/externalLinks.ts"), // modifyRelForExternalLinks
            import("@/site-header/footer.ts"), // hideFooterForLoggedInUsers
            import("@/site-header/vueApps.ts"), // initDeferredVueApps
            import("@/site-header/helpModule.ts"), // initHelpModule
            import("@/site-header/scrollEvents.ts"), // handleHashScroll, handleScrollEvents
            import("@/site-header/googleAds.ts"),
            import("@/site-header/languageNotification.ts")
        ])

        // Once all modules are loaded, initialize the functionality
        const pinia = createPinia()
        await loadTranslations()
        handleHashScroll()
        handleScrollEvents()
        initMenu(elements)
        bindUserMenuBtn(pinia)
        initPopupBladeClose()
        initShowHidePassword(elements)
        initPasswordStrengthChecker(elements)
        initLogoutButtons(elements)
        initDOMContentLoaded()
        setInputTokens(elements)
        initVerifyResend(elements)
        handlePackageReload()
        bindHistoryGoBack(elements)
        bindCertificateDownload()
        bindUseContentButtons(elements)
        preventDefaultDownloadButtons(elements)
        initSeoVideo(elements)
        modifyRelForExternalLinks()
        hideFooterForLoggedInUsers()
        initDeferredVueApps(pinia, elements)
        initHelpModule()
        googleAdsInit()
        lanugageNotificationInit(elements)
    }, 1000)
}
